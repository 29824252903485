import {css, cx, keyframes} from '@emotion/css';


// const animations = {
//   ocean: keyframes({
//     '0%': {
//       backgroundPosition: '50% 50%',
//     },
//     '20%': {
//       backgroundPosition: '50% 60%',
//     },
//     '60%': {
//       backgroundPosition: '50% 40%',
//     },
//     '100%': {
//       backgroundPosition: '50% 50%',
//     }
//   }),
// };


const styles = {
  app: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: (
      `linear-gradient(` +
        `180deg,` +
        // `#01579B 0%,` +
        `#01579B 0%,` +
        `#000000 100%` +
      `)`
    ),
    backgroundSize: '200% 200%',
    backgroundPosition: '50% 100%',
    // animation: `${animations.ocean} 2000ms ease-in-out infinite`,
  }),
  header: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  logo: css({
    display: 'block',
    width: '100%',
    maxWidth: '1200px',
  }),
  footer: css({
    padding: '16px 16px 32px 16px',
    color: '#F5F5F5',
    fontFamily: 'monospace',
    textAlign: 'center',
    '& a': {
      color: '#81D4FA',
    }
  }),
};

function App() {
  return (
    <div className={cx(styles.app)}>
      <div className={cx(styles.header)}>
        <img
          className={cx(styles.logo)}
          alt="Kenimo Logo"
          src="/assets/kenimo.light.vertical.png"
        />
      </div>
      <div className={cx(styles.footer)}>
        Decentralized Developer Tools
        <div>
          <a href="mailto:morgan@kenimo.com">morgan@kenimo.com</a>
        </div>
      </div>
    </div>
  );
}

export {App as default};
